/**
 * Basic properties of the application as they are presented on the user
 * interface.
 */
export const appDescriptor = {
    name: "Optimax",
};

/**
 * Whether the app is being built in production mode. This is set by
 * Webpack at bundling time. In the absence of a Webpack bundler, this
 * will evaluate to `false`.
 */
export const isInProductionMode = process.env.NODE_ENV === "production";

/**
 * The default locale for Numbro and other locale-aware libraries that we
 * might use.
 */
export const defaultLocale = "en-US";

/**
 * Whether API calls should be made only if we are currently authenticated
 * to the API.
 */
export const loginRequired = true;

/**
 * Event for schedule chart refreshing.
 */
export const scheduleChartRefreshEvent = "scheduleChartRefresh";

/**
 * Wheter the perspective settings menu button is enabled.
 */
export const isPerspectiveSettingsMenuButtonEnabled = true;

/**
 * Constant for workbench in local storage.
 */
export const localStorageConstantForWorkbench = "workbench";

/**
 * Constant for drawers state in local storage.
 */
export const localStorageConstantForDrawers = "drawers";

/**
 * Constant for sidebar state in local storage.
 */
export const localStorageConstantForSidebar = "sidebar";

/**
 * Constant for perspectives state in local storage.
 */
// export const localStorageConstantForPerspectives = "perspectives";

/**
 * Constant for selected perspective id in local storage.
 */
export const localStorageConstantForSelectedPerspectiveId = "selectedPerspectiveId";

/**
 * Constant for version of the stored data in local storage.
 */
export const localStorageConstantForLocalStorageVersion = "version";

/**
 * Version number for the stored data in the local storage.
 */
export const localStorageVersion = "2022-03-03";

/**
 * Constant for user data scope change event.
 * Emited by eventBus when user changes its data scope.
 */
export const userDataScopeChangeEvent = "userDataScopeChange";

/**
 * Constant for forecast calculation panel event
 * When forecast panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const forecastCalculationChangeEvent = "forecastCalculationChange";

/**
 * Constant for safety stock calculation panel event
 * When safety stock panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const safetyStockCalculationChangeEvent = "safetyStockCalculationChange";

/**
 * Method key for constant forecast algorithm
 */
export const PREDEFINED_CONSTANT_FORECAST_METHOD = 10001;

/**
 * Method key for constant safety stock algorithm
 */
export const PREDEFINED_CONSTANT_SAFETY_STOCK_METHOD = -1;

/**
 * This constant corresponds to a duration of one day minus 20% in milliseconds. This is
 * needed to fix a bug with overlay barmode in Plotly.js see
 * optimax-web/#43 on Gitlab.
 */
// export const FORECAST_SAFETYSTOCK_BAR_WIDTH = 1000 * 60 * 60 * 19.2;

// export const refreshProductionLineStatusTableEvent = "refreshProductionLineStatusTableEvent";

export const refreshSimpleProductionTableEvent = "refreshSimpleProductionTableEvent";

/**
 * WebSocket URL
 */
export const WEBSOCKET_URL: string | undefined = process.env.WEBSOCKET_URL;

/**
 * Socket.IO path (optional)
 */
export const SOCKETIO_PATH: string | undefined = process.env.SOCKETIO_PATH;

/**
 * Filter the perspectives by permissions.
 */
export const ENABLE_PERSPECTIVE_FILTERING_BY_PERMISSIONS = true;

/**
 * Constant for the historical consumption table change event
 */
export const historicalConsumptionTableChangeEvent = "historicalConsumptionTableChangeEvent";

/**
 * Websocket transport options (the default values are polling and websocket)
 */
export const WEBSOCKET_TRANSPORT = (process.env.WEBSOCKET_TRANSPORT ?? "polling,websocket").split(",");

/**
 * Schedule chart row height options
 */
export const scheduleChartSmallRowHeight = 30;
export const scheduleChartNormalRowHeight = 40;
export const scheduleChartLargeRowHeight = 60;
export const scheduleChartExtraLargeRowHeight = 100;

/**
 * The key is the number of days from now.
 */
export const omyaOverviewInventoryDateRanges = [
    { key: 14, label: "14 days", text: "Show 14 days" },
    { key: 30, label: "30 days", text: "Show 30 days" },
    { key: 45, label: "45 days", text: "Show 45 days" },
    { key: 90, label: "90 days", text: "Show 90 days" },
    { key: undefined, label: "All", text: "Show all" },
];

export const refreshProductionPlanEvent = "refreshProductionPlanEvent";

export const refreshLoadingPlanExceptLoadingPlanTableEvent = "refreshLoadingPlanExceptLoadingPlanTableEvent";

export const refreshLoadingPlanEvent = "refreshLoadingPlanEvent";

export const QUAY_LIST_TRANSPORT_STATUSES = [
    { key: "all", value: "All" },
    { key: "fixed", value: "Fixed" },
] as const;

export const QUAY_LIST_TRANSPORT_TYPES = [
    { key: "all", value: "All" },
    { key: "barge,vessel", value: "Barge, vessel" }, // The `barge,vessel` with a comma is what the server can accept
] as const;

export const COMMIT_HASH_FILE_NAME = "commit-hash.txt"; // This value is also imported by the Webpack config file

export const ANNOUNCEMENTS_ROOT_URL: string | undefined = process.env.ANNOUNCEMENTS_ROOT_URL;

export const ANNOUNCEMENTS_BATCH_SIZE = 5;

export const LOAD_MORE_ANNOUNCEMENTS_AUTOMATICALLY = true;

export const SHOW_THE_TAG_NO_MORE_ANNOUNCEMENTS = false;

export const ANNOUNCEMENTS_POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes
